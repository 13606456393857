import type { ApiRequestMethod, ApiResponseState, ApiResponseBase, ApiResponseError } from './api.types'
import { useApiAuthToken } from './authToken.use'
// import { eventBus } from '@/shared/lib/eventBus'

// const EVENT_BUS_CONTEXT = 'api'
// const EVENT_BUS_TARGET = 'error'
const HTTP_STATUS_CODE_UNAUTHORIZED = 401

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function requestApi<R>(baseURL: string, method: ApiRequestMethod, path: string, body?: any) {
  const authToken = useApiAuthToken()
  const state: ApiResponseState<R> = {
    isOk: false,
    isError: false,
    isAuthError: false,
    isInvalid: false,
    data: undefined,
    statusCode: 0,
    error: undefined,
  }

  try {
    const token = authToken.get()?.accessToken
    const headers = {
      // 'X-Application': env.APPLICATION,
      // 'X-Version-Backend': env.VERSION_BACKEND,
      'X-Device-Id': deviceId(),
      'X-Session-Id': sessionId(),
      // Locale: languageBrowser(),
      Authorization: token ? `Bearer ${token}` : '',
    }

    const response = await $fetch<R & ApiResponseBase>(path, {
      baseURL,
      method,
      body,
      headers,
      onResponseError({ response }) {
        state.statusCode = response.status
        switch (response.status) {
          case HTTP_STATUS_CODE_UNAUTHORIZED:
            state.isAuthError = true
            break
          default:
            state.isError = true
        }

        throw response._data.error
      },
    })
    if (response.error) throw response.error
    //
    state.isOk = true
    state.data = response as (R & ApiResponseBase) | undefined
  } catch (error) {
    const errorResponse = error as ApiResponseError
    state.isError = true
    state.error = {
      code: errorResponse?.code,
      message: errorResponse?.message,
    }
    state.isInvalid = true
  }

  return Object.freeze(state)
}
