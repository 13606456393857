import type { ApiRefreshTokenResponse } from './api.types'
import { useApiAuthToken } from './authToken.use'
import { requestApi } from './request'

const API_REFRESH_REQUEST_PATH = '/auth/token/refresh'

export async function refreshToken(baseURL: string) {
  const authToken = useApiAuthToken()
  if (!useApiAuthToken().get()?.refreshToken) return false
  const response = await requestApi<ApiRefreshTokenResponse>(baseURL, 'POST', API_REFRESH_REQUEST_PATH, {
    refreshToken: useApiAuthToken().get()?.refreshToken,
  })
  if (response.isOk && response.data?.success) {
    authToken.set({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresIn: response.data.expiresIn,
    })
    return true
  }
  authToken.clear()
  return false
}
