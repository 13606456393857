import { env } from '@/shared/config'

import type { ApiRequestMethod } from './api.types'
import { emitter } from './eventBus'
import { refreshToken } from './refreshToken'
import { requestApi } from './request'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function useApi<R>(path: string, method: ApiRequestMethod = 'GET', body?: any) {
  const response = await requestApi<R>(env.API_URL, method, path, body)
  if (response.isAuthError) {
    const refreshResponse = await refreshToken(env.API_URL)
    if (!refreshResponse) {
      emitter.emit('unauthorized')
      return response
    }
    return await requestApi<R>(env.API_URL, method, path, body)
  }
  return response
}

export function useApiErrorUnauthorized(callback: () => void) {
  return {
    on: () => emitter.on('unauthorized', callback),
    off: () => emitter.off('unauthorized', callback),
  }
}
