import { useStorage } from '@vueuse/core'

type Cache = {
  accessToken?: string
  refreshToken?: string
  expiresIn?: number
}

const CACHE_NAME = 'auth-token'

export function useApiAuthToken() {
  const cacheObj = useStorage<Cache>(CACHE_NAME, {}, localStorage)

  return {
    get: () => cacheObj.value,
    set: (token: Cache) => {
      cacheObj.value = token
    },
    clear: () => {
      cacheObj.value = null
    },
  }
}
